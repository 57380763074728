import { useState } from 'react'
import { ScanDeviceInfo } from 'src/api/scanner'
import {
  JsonView,
  darkStyles,
  defaultStyles,
  collapseAllNested,
} from 'react-json-view-lite'
import 'react-json-view-lite/dist/index.css'

interface MBusScannerResultRowProps {
  devInfo: ScanDeviceInfo
}

export const MBusScannerResultRow: React.FC<MBusScannerResultRowProps> = ({
  devInfo,
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const [showRaw, setShowRaw] = useState(false)

  // id formater
  const idFormat = (num: number): string => {
    const str = num.toString()
    return '0'.repeat(8 - str.length) + str
  }

  // format helper
  const toHex = (num: number, decimals: number): string => {
    let hexStr = num.toString(16).toUpperCase()
    return '0'.repeat(decimals - hexStr.length) + hexStr
  }

  return (
    <tbody className="table-group-divider">
      <tr>
        <td className="align-middle">
          {devInfo.primaryAddr !== undefined && (
            <>
              {devInfo.primaryAddr}
              <sub>
                <small className="text-muted">(10)</small>
              </sub>
            </>
          )}
        </td>
        <td className="text-end align-middle">{idFormat(devInfo.id)}</td>
        <td className="text-end align-middle">
          {devInfo.manStr} ({toHex(devInfo.manNum, 4)})
        </td>
        <td className="text-end align-middle">{toHex(devInfo.ver, 2)}</td>
        <td className="text-end align-middle">{toHex(devInfo.medium, 2)}</td>
        <td className="text-center">
          {devInfo.rawFrame != undefined && (
            <button
              type="button"
              className={`btn btn-outline-${showRaw ? 'primary' : 'secondary'} btn-sm mx-1`}
              onClick={() => setShowRaw((prev) => !prev)}
            >
              {showRaw ? 'Hide Raw' : 'Show Raw'}
            </button>
          )}
        </td>
        <td className="text-center">
          {devInfo.rawFrame != undefined && (
            <button
              type="button"
              className={`btn btn-outline-${showDetails ? 'primary' : 'secondary'} btn-sm mx-1`}
              onClick={() => setShowDetails((prev) => !prev)}
            >
              {showDetails ? 'Hide Parsed' : 'Show Parsed'}
            </button>
          )}
        </td>
      </tr>

      {showRaw && (
        <tr>
          <td colSpan={7}>{devInfo.rawFrame}</td>
        </tr>
      )}

      {showDetails && (
        <tr>
          {devInfo.parsedFrame !== undefined && (
            <td colSpan={7}>
              <JsonView
                data={devInfo.parsedFrame}
                shouldExpandNode={collapseAllNested}
                clickToExpandNode={true}
                style={darkStyles}
              />
            </td>
          )}
          {devInfo.parsedFrame === undefined && (
            <td colSpan={7}>
              Response corrupted. Try parsing using{' '}
              <a
                target="_blank"
                href="https://backend.wmbus.acrios.com/#/Parsers/parse_provided_input_as_hex_mbus_parser_mbus_hex_get"
              >
                M-Bus Parser
              </a>
              .
            </td>
          )}
        </tr>
      )}
    </tbody>
  )
}
