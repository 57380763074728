import molecule from '@dtinsight/molecule'
import { Float, IStatusBarItem } from '@dtinsight/molecule/esm/model'
import { DEVICE_STATE } from '../api/serialProvider'
import { showOutputPanel } from '../common'

/**
 * Gets contrast color to provided color
 * @param color Hex color string (3 or 6 chars)
 * @returns Hex color string for white or black color
 */
const getContrastColor = (color: string): string => {
  color = color.replace('#', '')

  if (color.length === 3) {
    color =
      color.charAt(0) +
      color.charAt(0) +
      color.charAt(1) +
      color.charAt(1) +
      color.charAt(2) +
      color.charAt(2)
  } else if (color.length !== 6) {
    console.error('Wrong hex color format!', color)
    return '#fff'
  }

  let rgb = []
  for (let i = 0; i <= 2; i++) {
    rgb[i] = parseInt(color.substring(i * 2, i * 2 + 2), 16)
  }

  const luma = 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2] // SMPTE C, Rec. 709 weightings
  return luma >= 165 ? '#000' : '#fff'
}

/**
 * Returns StatusBar info message based on device state
 */
const deviceStateMessages = (state: DEVICE_STATE) => {
  switch (state) {
    case DEVICE_STATE.SERIAL_PORT_CLOSED:
      return 'Serial port not chosen'
    case DEVICE_STATE.BL_ONLY:
      return 'Device has no firmware, connected to legacy bootloader...'
    case DEVICE_STATE.NG_BL_ONLY:
      return 'Device has no firmware, connected to bootloader...'
    case DEVICE_STATE.CONNECTING:
      return 'Trying to connect to a device...'
    case DEVICE_STATE.CONNECTED:
      return 'Device connected'
    case DEVICE_STATE.READING:
      return 'Device connected - reading Lua script...'
    case DEVICE_STATE.WRITING:
      return 'Device connected - writing Lua script...'
    case DEVICE_STATE.RESTARTING:
      return 'Restarting the device... It will reconnect shortly.'
    case DEVICE_STATE.FW_UPDATE:
      return 'Firmware update in progress... (legacy bootloader)'
    case DEVICE_STATE.FW_UPDATE_NG:
      return 'Firmware update in progress...'
    case DEVICE_STATE.INTERACTIVE_MODE:
      return 'Lua interactive mode'
    case DEVICE_STATE.SCAN_MODE:
      return 'Scanning M-Bus...'
    default:
      return 'App is starting up.'
  }
}

/**
 * Returns StatusBar color based on device state
 */
const getStatusBarColor = (state: DEVICE_STATE) => {
  switch (state) {
    case DEVICE_STATE.CONNECTING:
      return '#0A5E61'

    case DEVICE_STATE.CONNECTED:
      return '#2d6304' // dark green

    case DEVICE_STATE.READING:
    case DEVICE_STATE.WRITING:
    case DEVICE_STATE.FW_UPDATE:
    case DEVICE_STATE.FW_UPDATE_NG:
      return '#7a6a00' // olive

    case DEVICE_STATE.SCAN_MODE:
    case DEVICE_STATE.INTERACTIVE_MODE:
      return '#FFCC00' // yellow

    default:
      return '#75000a' // red
  }
}

/**
 * Use Molecule output panel hook
 */
export const useStatusBar = () => {
  /**
   * Updates StatusBar
   */
  const updateStatusBar = (state: DEVICE_STATE, message?: string, onClick?) => {
    const DEVICE_STATUS_BAR: IStatusBarItem = {
      id: 'deviceStatusBar',
      name: 'DeviceStatus',
      render: () => <b></b>,
      onClick: () => {},
    }

    // status bar background color
    const bgColor = getStatusBarColor(state)
    document.documentElement.style.setProperty(
      '--statusBar-background',
      bgColor
    )
    document.documentElement.style.setProperty(
      '--statusBar-foreground',
      getContrastColor(bgColor)
    )

    // status bar message
    const text = message ?? deviceStateMessages(state) ?? ''
    let payload = {
      ...DEVICE_STATUS_BAR,
      render: () => <p className="mb-0">{text}</p>,
    }

    if (onClick) {
      payload.onClick = onClick
    }

    const deviceStatusBar = molecule.statusBar.getStatusBarItem(
      DEVICE_STATUS_BAR.id
    )
    if (!deviceStatusBar) {
      molecule.statusBar.add(payload, Float.left)
    } else {
      molecule.statusBar.update(payload, Float.left)
    }
  }

  /**
   * Initialize StatusBar
   */
  const init = () => {
    molecule.statusBar.remove(
      molecule.builtin.getConstant('STATUS_PROBLEMS')!.value
    )
    showOutputPanel()
    updateStatusBar(DEVICE_STATE.SERIAL_PORT_CLOSED)
  }

  return {
    init,
    updateStatusBar,
  }
}
