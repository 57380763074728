import { Buffer } from 'buffer'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import {
  prepareWriteLuaPayload,
  prepareReadLuaPayload,
  sendFrame,
  LUA_SCRIPT_MODE,
} from './serialApi'
import { AcriosSerial } from './serialDriver'
import molecule from '@dtinsight/molecule'
import { useInterval } from 'usehooks-ts'
import {
  NotificationLevel,
  showNotification,
  showOutputPanel,
} from '../common/'

import FrameTypes from './frameTypes'

import { LuaAction } from '../extensions/luaCompiler/base'

import { bootloaderStart, legacySerialBootloaderFSM } from './legacyBootloader'

import { serialBootloaderStart, serialBootloaderFSM } from './bootloader'

import { FSM_MODE, luaFSMStart, luaFiniteStateMachine } from './luaReadWrite'

import { bufferToHex } from './helpers'
import { TERMINAL_ID } from '../extensions/terminal/base'

import { startDetector, detectStateFSM } from './deviceStateDetector'
import { FW_VERSION } from './fwVersion'
import { getCurrentTime } from '../utils/timeUtils'
import { lookForACK } from './serialProvider/lookForACK'

import { useOutputPanel } from '../hooks/useOutputPanel'
import { useStatusBar } from '../hooks/useStatusBar'
import { useGlobalState } from '../state/state'
import { getLuaApi } from './lua/luaApi'
import { LuaWriteParams } from 'src/types'
import { parseVersionFromRawStr } from 'src/utils/versionUtils'
import { ScanContext, Scanner } from './scanner'

//
// Context
//
export type SerialContextType = {
  port: React.MutableRefObject<AcriosSerial>
  deviceState: React.ComponentState
  deviceInfo: React.MutableRefObject<DeviceInfo>
  openClosePort: () => void
  switchToConfigMode: () => void
  changeDeviceState: (newState: DEVICE_STATE) => void
}

const SerialContext = React.createContext<SerialContextType | null>(null)

//
// Enums
//
enum DEVICE_STATE {
  SERIAL_PORT_CLOSED = 'serialPortClosed',
  CONNECTION_LOST = 'connectionLost',
  SERIAL_LOGGING = 'serialLogging',
  CONNECTING = 'connecting',
  FW_UPDATE = 'fwUpdate',
  FW_UPDATE_NG = 'fwUpdateNg',
  INTERACTIVE_MODE = 'interactiveMode',
  NG_BL_ONLY = 'ngBlOnly',
  BL_ONLY = 'blOnly',
  CONNECTED = 'connected',
  READING = 'reading',
  WRITING = 'writing',
  RESTARTING = 'restarting',
  SCAN_MODE = 'scanMode',
}

enum EVENT_TYPE {
  PERIODIC = 'periodic',
  ON_RECEIVED = 'onReceived',
}

export type DeviceInfo = {
  detected: boolean
  type: string
  fwVersion: FW_VERSION
  legacy: boolean
}

//
// Constants
//
const PING_RATE_MS = 500
const DISCONNECT_TRESHOLD_MS = PING_RATE_MS * 50
const PERIODIC_TICK_MS = 10
const TERM_PROMPT = '~>'

const KEYS = {
  TAB: 0x9,
  LF: 0xa,
  CR: 0xd,
}

const defaultDeviceInfo = {
  detected: false,
  type: '',
  fwVersion: {} as FW_VERSION,
  legacy: false,
}

/**
 * Main serial component
 */
const SerialProvider = ({ children }) => {
  const statusBar = useStatusBar()
  const outputPanel = useOutputPanel()
  const [deviceState, setDeviceState] = useState<DEVICE_STATE>(
    DEVICE_STATE.SERIAL_PORT_CLOSED
  )

  const port = useRef<AcriosSerial>(new AcriosSerial())
  const lastPong = useRef<number>(undefined)
  const pingTries = useRef<number>(0)
  const iModeLastCmd = useRef<Uint8Array>()
  const finalizeReception = useRef(undefined)
  const deviceInfo = useRef<DeviceInfo>(defaultDeviceInfo)

  const [_deviceDetected, setDeviceDetected] = useGlobalState('deviceDetected')
  const [_deviceType, setDeviceType] = useGlobalState('deviceType')
  const [_bootLoaderLegacy, setBootLoaderLegacy] =
    useGlobalState('bootLoaderLegacy')
  const [localEchoTerm, _setLocalEchoTerm] = useGlobalState('localEchoTerm')

  const [_deviceFwVersion, setDeviceFwVersion] =
    useGlobalState('deviceFwVersion')

  const [_scanner, setScanner] = useState<Scanner>(undefined)

  // useEffect(() => {
  //   molecule.panel.cleanOutput()
  //   molecule.panel.appendOutput(`[${getCurrentTime()}] STATE: ${deviceState}\n`);
  // }, [deviceState])

  /**
   * Switch to config mode
   */
  const switchToConfigMode = async () => {
    changeDeviceState(DEVICE_STATE.CONNECTING)
  }

  /**
   * Change device state and update UI accordingly
   */
  const changeDeviceState = useCallback(
    (newState: DEVICE_STATE) => {
      if (deviceState !== newState) {
        console.log(
          `%cChanging device state from ${deviceState} to ${newState}`,
          'color: #00ff00'
        )

        if (deviceState === DEVICE_STATE.CONNECTED) {
          lastPong.current = undefined
        }

        setDeviceState(newState)

        if (newState === DEVICE_STATE.SERIAL_PORT_CLOSED) {
          statusBar.updateStatusBar(DEVICE_STATE.SERIAL_PORT_CLOSED)
          deviceInfo.current = defaultDeviceInfo
        } else {
          statusBar.updateStatusBar(newState)
        }

        if ([DEVICE_STATE.CONNECTING].includes(newState)) {
          startDetector()
        }
      }
    },
    [deviceState]
  )

  /**
   * Open port via web serial api
   */
  const openClosePort = useCallback(async () => {
    try {
      const isActionClose =
        port.current.isConnected &&
        deviceState !== DEVICE_STATE.SERIAL_PORT_CLOSED
      if (port.current.isConnected) {
        await port.current.disconnect()
        if (finalizeReception.current !== undefined) {
          console.log('[SERIAL PROVIDER] Wait for old receiver to terminate')
          await Promise.resolve(finalizeReception.current)
          console.log(
            '[SERIAL PROVIDER] Done waiting for old receiver to terminate'
          )
        }
      }

      if (isActionClose) {
        changeDeviceState(DEVICE_STATE.SERIAL_PORT_CLOSED)
        return
      }

      const isOpen = await port.current.connect()
      if (isOpen) {
        console.log('[SERIAL PROVIDER] Opening serial port')

        try {
          // changeDeviceState(DEVICE_STATE.CONNECTING);

          changeDeviceState(DEVICE_STATE.SERIAL_LOGGING)

          finalizeReception.current = new Promise((resolve, reject) => {
            const keepReceiving = async () => {
              console.warn('[SERIAL PROVIDER] Reception restarting')
              for await (let { done } of port.current.readLineGenerator()) {
                if (done === true) {
                  console.warn(
                    '[SERIAL PROVIDER] Reading from serial line aborted'
                  )
                  break
                }
              }
              if (port.current.isConnected) {
                setTimeout(keepReceiving, 100)
              } else {
                console.warn(
                  '[SERIAL PROVIDER] Reception terminating without restart!'
                )
                resolve('done')
              }
            }
            keepReceiving()
          })
        } catch (error) {
          console.error(
            '[SERIAL PROVIDER] Error while opening & locking the serial port',
            error
          )
          changeDeviceState(DEVICE_STATE.SERIAL_PORT_CLOSED)
        }
      }
    } catch (error) {
      console.error(
        '[SERIAL PROVIDER] Unknown error occured while opening the port',
        error
      )
      changeDeviceState(DEVICE_STATE.SERIAL_PORT_CLOSED)
    }
  }, [deviceState, changeDeviceState])

  // context
  const context = {
    port,
    deviceState,
    deviceInfo,
    openClosePort,
    switchToConfigMode,
    changeDeviceState,
  }

  // interval that "pings" the device to keep connection alive
  useInterval(
    async () => {
      // send ping frame
      console.log('%cPING!', 'color: #3c85f2')
      const res = await sendFrame(FrameTypes.NOOP, null, false, port.current)
      if (res === false) {
        return
      }
      pingTries.current += 1

      if (lastPong.current === undefined) {
        changeDeviceState(DEVICE_STATE.CONNECTING)

        // try to disable interactive mode
        if (pingTries.current > 5) {
          console.log('Trying to disable forgotten iteractive mode...')
          await port.current.write(new TextEncoder().encode('cont\r\n'), true)
          pingTries.current = 0
        }
      } else if (Date.now() - lastPong.current >= DISCONNECT_TRESHOLD_MS) {
        //Ping time-out, device froze or disconnected
        changeDeviceState(DEVICE_STATE.CONNECTION_LOST)
      } else {
        //Ping received, device is sucessfully connected
        changeDeviceState(DEVICE_STATE.CONNECTED)
        pingTries.current = 0
      }
    },
    [DEVICE_STATE.CONNECTED, DEVICE_STATE.CONNECTION_LOST].includes(deviceState)
      ? PING_RATE_MS
      : null
  )

  useInterval(
    () => {
      switch (deviceState) {
        case DEVICE_STATE.READING:
        case DEVICE_STATE.WRITING:
          luaFiniteStateMachine(port, EVENT_TYPE.PERIODIC, onFinishedCallback)
          break
      }
    },
    [DEVICE_STATE.READING, DEVICE_STATE.WRITING].includes(deviceState)
      ? PERIODIC_TICK_MS
      : null
  )

  useInterval(
    async () => {
      await serialBootloaderFSM(port, EVENT_TYPE.PERIODIC, onFinishedCallback)
    },
    deviceState === DEVICE_STATE.FW_UPDATE_NG ? 5 : null
  )

  useInterval(
    async () => {
      await legacySerialBootloaderFSM(
        port,
        EVENT_TYPE.PERIODIC,
        onFinishedCallback
      )
    },
    deviceState === DEVICE_STATE.FW_UPDATE ? 5 : null
  )

  useInterval(
    async () => {
      await detectStateFSM(port, EVENT_TYPE.PERIODIC, onFinishedCallback)
    },
    [DEVICE_STATE.CONNECTING, DEVICE_STATE.RESTARTING].includes(deviceState)
      ? 50
      : null
  )

  /**
   * On finished callback to change device state
   */
  const onFinishedCallback = useCallback(
    async (proposedState?: DEVICE_STATE, data?: DeviceInfo) => {
      console.warn(`onFinishedCallback: ${deviceState} => ${proposedState}`)

      switch (deviceState) {
        case DEVICE_STATE.FW_UPDATE_NG:
        case DEVICE_STATE.FW_UPDATE:
          changeDeviceState(DEVICE_STATE.RESTARTING)
          sendFrame(FrameTypes.RESET_BOARD, null, false, port.current)
          break
        case DEVICE_STATE.WRITING:
          changeDeviceState(DEVICE_STATE.RESTARTING)
          sendFrame(FrameTypes.RESET_BOARD, null, false, port.current)
          changeDeviceState(DEVICE_STATE.CONNECTED)
          break

        case DEVICE_STATE.CONNECTING:
        case DEVICE_STATE.RESTARTING:
          if (deviceState === DEVICE_STATE.CONNECTING && data !== undefined) {
            deviceInfo.current = data as DeviceInfo

            // TODO: can not use server fetching

            // const luaApi = await fetch(`http://localhost:3001/api/lua?version=${version}`)
            // const parsedApi = await luaApi.json()
            // const funcs = parsedApi.links.map(l => l.fn)
            // console.log(funcs)

            // // autocomplete
            // localEchoTerm.addAutocompleteHandler((index) => {
            //   if (index !== 0) return []

            //   return funcs
            // })

            setDeviceDetected(data.detected)
            setDeviceType(data.type)
            setBootLoaderLegacy(data.legacy)

            const parsedFwVersion = parseVersionFromRawStr(data.fwVersion.raw)
            setDeviceFwVersion(parsedFwVersion)
          }

          changeDeviceState(proposedState)
          if (proposedState === DEVICE_STATE.CONNECTED) {
            lastPong.current = Date.now()
          }
          break

        default:
          changeDeviceState(DEVICE_STATE.CONNECTED)
      }
    },
    [deviceState, changeDeviceState]
  )

  // persistent buffer for received data to handle split ACK24 responses
  let persistentBuffer = []

  /**
   * Event handlers
   */
  useEffect(() => {
    // make sure that all events are first unsubscribed
    // so when useEffect is re-run, they have only one handler
    molecule.event.EventBus.unsubscribe([
      'DataReceived',
      LuaAction.READ,
      LuaAction.INTERACTIVE_MODE_START,
      LuaAction.INTERACTIVE_MODE_STOP,
      LuaAction.FW_UPDATE,
      LuaAction.FW_UPDATE_NG,
      LuaAction.SCAN_MODE_START,
      LuaAction.SCAN_MODE_STOP,
    ])

    // buffer for output logging
    let loggingBuffer = []

    // Data received from serial
    molecule.event.EventBus.subscribe(
      'DataReceived',
      async (bufferFull: Uint8Array) => {
        let msg = `=================================================\n`
        msg += `DataReceived! (${bufferFull.length} bytes):\n`
        msg += `${bufferToHex(bufferFull)}\n`
        msg += `${Buffer.from(bufferFull).toString('ascii')}\n`
        msg += `=================================================`
        console.log(msg)

        // buffer for FSM, do not touch!!
        const buffer = Array.from(bufferFull)

        const loggingStates = [
          DEVICE_STATE.SERIAL_LOGGING,
          DEVICE_STATE.CONNECTING,
        ]
        if (loggingStates.includes(deviceState)) {
          loggingBuffer.push(...Array.from(bufferFull))
          setTimeout(() => {
            let bufferTxt = Buffer.from(loggingBuffer).toString('ascii').trim()

            if (bufferTxt.length > 0) {
              bufferTxt = bufferTxt
                .split('\n')
                .map((row) => `[${getCurrentTime()}] `.concat(row))
                .join('\n')

              if (bufferTxt[bufferTxt.length - 1] !== '\n') {
                bufferTxt = bufferTxt.concat('\n')
              }

              molecule.panel.appendOutput(bufferTxt)
            }

            loggingBuffer = []
          }, 200)

          outputPanel.scrollDown()
        }

        switch (deviceState) {
          case DEVICE_STATE.SERIAL_LOGGING:
            break
          case DEVICE_STATE.READING:
          case DEVICE_STATE.WRITING:
            luaFiniteStateMachine(
              port,
              EVENT_TYPE.ON_RECEIVED,
              onFinishedCallback,
              buffer
            )
            break

          case DEVICE_STATE.FW_UPDATE_NG:
            await serialBootloaderFSM(
              port,
              EVENT_TYPE.ON_RECEIVED,
              onFinishedCallback,
              buffer
            )
            break
          case DEVICE_STATE.FW_UPDATE:
            await legacySerialBootloaderFSM(
              port,
              EVENT_TYPE.ON_RECEIVED,
              onFinishedCallback,
              buffer
            )
            break

          case DEVICE_STATE.RESTARTING:
          case DEVICE_STATE.CONNECTING:
            await detectStateFSM(
              port,
              EVENT_TYPE.ON_RECEIVED,
              onFinishedCallback,
              buffer
            )
            break

          case DEVICE_STATE.CONNECTED:
            // look for ack
            persistentBuffer.push(...Array.from(bufferFull))
            const res = lookForACK(persistentBuffer)
            // reset persistent buffer if its longer than 5 bytes (ACK24 is 5 bytes long)
            if (persistentBuffer.length >= 5) {
              persistentBuffer = []
            }
            if (res.acked) {
              lastPong.current = Date.now()
            }

            // append data to output panel
            // TODO: better cleanup
            if (res.cleanBuffer.length > 0) {
              molecule.panel.appendOutput(
                Buffer.from(
                  buffer.filter(
                    (b) =>
                      (b > 31 && b < 127) ||
                      b === KEYS.TAB ||
                      b === KEYS.LF ||
                      b === KEYS.CR
                  )
                ).toString('ascii')
              )
            }
            break

          case DEVICE_STATE.INTERACTIVE_MODE:
            // filter out sent out command from incoming data
            let count = 0
            if (iModeLastCmd.current) {
              for (let i = 0; i < bufferFull.length; i += 1) {
                if (bufferFull[i] !== iModeLastCmd.current[i]) {
                  break
                }
                count++
              }
              if (count > 0) {
                bufferFull = bufferFull.slice(count)
                iModeLastCmd.current = iModeLastCmd.current.slice(count)
              }
            }

            if (bufferFull.length > 0) {
              ;(window as any).term.write(bufferFull)
            }
            break

          case DEVICE_STATE.SCAN_MODE:
            _scanner.processChunk(bufferFull)
            break
        }
      }
    )

    // Lua read
    molecule.event.EventBus.subscribe(LuaAction.READ, () => {
      const frames = prepareReadLuaPayload()
      luaFSMStart(FSM_MODE.READ, frames)

      changeDeviceState(DEVICE_STATE.READING)
    })

    // Lua interactive mode start
    molecule.event.EventBus.subscribe(LuaAction.INTERACTIVE_MODE_START, () => {
      changeDeviceState(DEVICE_STATE.INTERACTIVE_MODE)

      molecule.panel.setActive(TERMINAL_ID)

      sendFrame(
        FrameTypes.START_LUA_INTERACTIVE_MODE,
        null,
        false,
        port.current
      )

      // const localEchoTerm = (window as any).localEchoTerm

      // TODO: cancel when interactive mode is stopped
      const readLine = () => {
        localEchoTerm.read(`${TERM_PROMPT} `).then(async (input) => {
          input = input.replaceAll('\n', ' ').replaceAll('\r', ' ')
          const bytes = new TextEncoder().encode(`${input}\r\n`)
          await port.current.write(bytes, true)
          iModeLastCmd.current = bytes
          readLine()
        })
      }
      readLine()
    })

    molecule.event.EventBus.subscribe(
      LuaAction.INTERACTIVE_MODE_STOP,
      async () => {
        await port.current.write(
          new TextEncoder().encode('cont\r\ncont\r\ncont\r\n'),
          true
        )

        showOutputPanel()

        changeDeviceState(DEVICE_STATE.CONNECTED)
      }
    )

    // Scan mode start
    molecule.event.EventBus.subscribe(
      LuaAction.SCAN_MODE_START,
      (ctx: ScanContext) => {
        console.log('SCAN_MODE_START subscriber')
        setScanner(new Scanner(ctx))
        changeDeviceState(DEVICE_STATE.SCAN_MODE)
      }
    )

    // Scan mode stop
    molecule.event.EventBus.subscribe(LuaAction.SCAN_MODE_STOP, () => {
      console.log('SCAN_MODE_STOP subscriber')
      changeDeviceState(DEVICE_STATE.CONNECTED)
    })

    // Firmware update
    molecule.event.EventBus.subscribe(LuaAction.FW_UPDATE, async (fw) => {
      changeDeviceState(DEVICE_STATE.FW_UPDATE)
      bootloaderStart(fw)
    })

    // Firmware update - non-legacy, NG
    molecule.event.EventBus.subscribe(LuaAction.FW_UPDATE_NG, async (fw) => {
      changeDeviceState(DEVICE_STATE.FW_UPDATE_NG)
      await serialBootloaderStart(fw)
    })
  }, [
    deviceState,
    changeDeviceState,
    onFinishedCallback,
    outputPanel,
    _scanner,
  ])

  return (
    <SerialContext.Provider value={context}>{children}</SerialContext.Provider>
  )
}

export default SerialProvider
export { EVENT_TYPE, SerialContext, DEVICE_STATE }
