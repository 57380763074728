export const enum LuaAction {
  VALIDATE = 'lua.validate',
  DEPLOY = 'lua.deploy',
  READ = 'lua.read',
  WRITE = 'lua.write',
  FW_UPDATE = 'lua.fwUpdate',
  FW_UPDATE_NG = 'lua.fwUpdateNg',
  INTERACTIVE_MODE_START = 'lua.startInteractiveMode',
  INTERACTIVE_MODE_STOP = 'lua.stopInteractiveMode',
  SCAN_MODE_START = 'lua.startScanMode',
  SCAN_MODE_STOP = 'lua.stOPScanMode',
}
