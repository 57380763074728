import { Buffer } from 'buffer'
import { parseInt } from 'lodash'
import { getCurrentTime } from 'src/utils/timeUtils'
import { bufferToHex } from './helpers'

export const LOGGER_VERSION = 1 // INFO: increment when making changes to the logger

export interface ScanDeviceInfo {
  primaryAddr: number
  id: number
  manNum: number
  manStr: string
  ver: number
  medium: number
  rawFrame: string
  parsedFrame: any
}

export enum ScanReqType {
  PRIMARY,
  SECONDARY,
  SECONDARY_UNICAST,
  TARGETED,
  AUTO,
}

export interface ScanContext {
  reqType: ScanReqType
  onStateInfo: (response: number[]) => void
  onScanDone: () => void
  onDeviceInfo: (addr: number, info: ScanDeviceInfo) => void
  onLogging: (msg: string) => void
  onFirstErrorState: () => void
  onErrorStateRecovery: () => void
  lastAddr?: number // PRIMARY only
}

enum ScanPriState {
  W4_SETUP = 'PRI_W4_SETUP',
  W4_ADDR = 'PRI_W4_ADDR',
  W4_DEVICE_INFO = 'PRI_W4_DEVICE_INFO',
  W4_DONE = 'PRI_W4_DONE',
  ERROR = 'PRI_ERROR',
}
enum ScanSecState {
  W4_SETUP = 'SEC_W4_SETUP',
  W4_MBUS_RESP = 'SEC_W4_MBUS_RESP',
  W4_RESULT = 'SEC_W4_RESULT',
  W4_DETAILS = 'W4_DETAILS',
  W4_DONE = 'SEC_W4_DONE',
  ERROR = 'SEC_ERROR',
}
type ScanState = ScanPriState | ScanSecState

export class Scanner {
  buffer: number[]
  bufferIdx: number
  context: ScanContext
  state: ScanState

  _currAddr: number
  _lastDevInfo: ScanDeviceInfo

  constructor(context: ScanContext) {
    this.buffer = []
    this.bufferIdx = 0
    this.context = context
    this.initState()
  }

  initState() {
    switch (this.context.reqType) {
      case ScanReqType.PRIMARY:
        this.state = ScanPriState.W4_SETUP
        break

      case ScanReqType.SECONDARY:
        this.state = ScanSecState.W4_SETUP
        break

      case ScanReqType.SECONDARY_UNICAST:
        this.state = ScanSecState.W4_SETUP
        break

      case ScanReqType.TARGETED:
        break
      case ScanReqType.AUTO:
        break
    }
  }

  processChunk(chunk: Uint8Array) {
    // push into scanBuffer until msg with "\r\n" is received
    this.buffer.push(...Array.from(chunk))

    this.context.onLogging(
      `[SERIAL][${getCurrentTime()}]: ${bufferToHex(Buffer.from(chunk)).trim()} == ${JSON.stringify(Buffer.from(chunk).toString('ascii'))}`
    )

    this.lookForNL()
  }

  lookForNL() {
    // look for "\r\n"
    while (this.bufferIdx < this.buffer.length - 1) {
      const i = this.bufferIdx

      if (this.buffer[i] == 13 && this.buffer[i + 1] == 10) {
        // found -> split buffer
        const line = this.buffer.slice(0, i) // exclude "\r\n"
        this.buffer = this.buffer.slice(i + 2) // remainder
        this.bufferIdx = 0

        // print line
        console.log('----------------------------------')
        console.log(JSON.stringify(line))
        console.log(JSON.stringify(Buffer.from(line).toString('ascii')))

        // process line
        this.processLine(line)
        console.log('----------------------------------')
      } else {
        this.bufferIdx++
      }
    }
  }

  processLine(line: number[]) {
    switch (this.context.reqType) {
      case ScanReqType.PRIMARY:
        this.primaryFSM(line)
        break
      case ScanReqType.SECONDARY:
        this.secondaryFSM(line)
        break
      case ScanReqType.SECONDARY_UNICAST:
        this.secondaryUnicastFSM(line)
        break
      case ScanReqType.AUTO:
        this.autoFSM(line)
        break
    }
  }

  nextState(newState: ScanState) {
    // TODO: report going into ERROR state
    this.context.onLogging(
      `[SCANNER][${getCurrentTime()}]: ${this.state} -> ${newState}`
    )

    // entering error state for the first time?
    if (
      newState != this.state &&
      ((this.context.reqType === ScanReqType.PRIMARY &&
        newState === ScanPriState.ERROR) ||
        ((this.context.reqType === ScanReqType.SECONDARY ||
          this.context.reqType === ScanReqType.SECONDARY_UNICAST) &&
          newState === ScanSecState.ERROR))
    ) {
      this.context.onFirstErrorState()
    }

    this.state = newState
  }

  primaryFSM(line: number[]) {
    const lineStr = Buffer.from(line).toString('ascii')

    switch (this.state) {
      // waiting for setup response
      case ScanPriState.W4_SETUP:
        const setupRV = lineStr.match(/-?\d+\t-?\d+\t-?\d+\t-?\d+\t-?\d+/)

        if (setupRV === null) {
          // check for old HW response
          if (lineStr.trim().match(/\[STDOUT\]:$/g) !== null) {
            this.context.onStateInfo([])
            this.nextState(ScanPriState.W4_ADDR)
            break
          }

          // ERROR: expected setup response, but didnt find it
          this.nextState(ScanPriState.ERROR)
          break
        }

        const nums = setupRV[0].trim().split('\t').map(parseInt)
        this.context.onLogging(
          `[SCANNER][${getCurrentTime()}]: MBUSSTATE=${JSON.stringify(nums)}`
        )
        this.context.onStateInfo(nums)
        this.nextState(ScanPriState.W4_ADDR)
        break

      // waiting for primary address response
      case ScanPriState.W4_ADDR:
        const addrResp = lineStr.match(/Primary=\d+/)

        if (addrResp === null) {
          // ERROR: expected primary address response, but didnt find it
          this.nextState(ScanPriState.ERROR)
          break
        }

        const primaryAddr = parseInt(addrResp[0].split('=')[1].trim())
        this.context.onLogging(
          `[SCANNER][${getCurrentTime()}]: Primary=${primaryAddr}`
        )
        this._currAddr = primaryAddr

        this.nextState(ScanPriState.W4_DEVICE_INFO)
        break

      // waiting for response form mbus device
      case ScanPriState.W4_DEVICE_INFO:
        if (lineStr.length == 0) {
          // no device connect at addr
          this.context.onLogging(`[SCANNER][${getCurrentTime()}]: NO_DEVICE`)
          this.context.onDeviceInfo(this._currAddr, undefined)
        } else {
          // found connected device
          const decodedDevInfo = this.decodePrimaryDeviceResponse(
            this._currAddr,
            lineStr
          )
          this.context.onLogging(
            `[SCANNER][${getCurrentTime()}]: DEVICE=${JSON.stringify(decodedDevInfo)}`
          )
          this.context.onDeviceInfo(this._currAddr, decodedDevInfo)
        }

        // not at last address?
        if (this._currAddr < this.context.lastAddr) {
          this.nextState(ScanPriState.W4_ADDR)
        } else {
          this.nextState(ScanPriState.W4_DONE)
        }
        break

      // waiting for "scan done"
      case ScanPriState.W4_DONE:
        if (lineStr.match(/DONE SCANNING/g) !== null) {
          this.context.onLogging(
            `[SCANNER][${getCurrentTime()}]: DONE_SCANNING`
          )
          this.context.onScanDone()
        } else {
          // expected "DONE SCANNING" but didnt find it
          this.nextState(ScanPriState.ERROR)
        }
        break

      // error happened
      case ScanPriState.ERROR:
        if (lineStr.match(/DONE SCANNING/g) !== null) {
          this.context.onLogging(`[SCANNER][${getCurrentTime()}]: RECOVERED`)
          this.context.onErrorStateRecovery()
        }
        break
    }
  }

  reversedToDec(hexChunks: string[]): number {
    hexChunks.reverse()
    return parseInt(hexChunks.join(''), 16)
  }

  manNum2Str(manNum: number): string {
    return String.fromCharCode(
      64 + ((manNum & (0x1f << 10)) >> 10),
      64 + ((manNum & (0x1f << 5)) >> 5),
      64 + (manNum & 0x1f)
    )
  }

  manStr2Num(manStr: string): number {
    // https://www.m-bus.de/man.html
    let k = (manStr.charCodeAt(0) - 64) * 1024
    k += (manStr.charCodeAt(1) - 64) * 32
    k += manStr.charCodeAt(2) - 64
    return k
  }

  decodePrimaryDeviceResponse(
    primaryAddr: number,
    response: string
  ): ScanDeviceInfo {
    response = response.trim()

    try {
      // @ts-ignore
      const parsedData = tmbus(response)

      return {
        primaryAddr,
        id: parsedData.id,
        manStr: parsedData.manId,
        manNum: this.manStr2Num(parsedData.manId),
        ver: parsedData.version,
        medium: parsedData.deviceCode,
        rawFrame: response,
        parsedFrame: parsedData,
      }
    } catch (error) {
      return {
        primaryAddr,
        id: 0,
        manStr: 'XXX',
        manNum: 0,
        ver: 0,
        medium: 0,
        rawFrame: response,
        parsedFrame: undefined,
      }
    }
  }

  secondaryFSM(line: number[]) {
    const lineStr = Buffer.from(line).toString('ascii')

    switch (this.state) {
      // waiting for setup response
      case ScanSecState.W4_SETUP:
        const setupRV = lineStr.match(/-?\d+\t-?\d+\t-?\d+\t-?\d+\t-?\d+/)

        if (setupRV === null) {
          // check for old HW response
          if (lineStr.trim().match(/\[STDOUT\]:$/g) !== null) {
            this.context.onStateInfo([])
            this.nextState(ScanSecState.W4_MBUS_RESP)
            break
          }

          // ERROR: expected setup response, but didnt find it
          this.nextState(ScanSecState.ERROR)
          break
        }

        const nums = setupRV[0].trim().split('\t').map(parseInt)
        this.context.onLogging(
          `[SCANNER][${getCurrentTime()}]: MBUSSTATE=${JSON.stringify(nums)}`
        )
        this.context.onStateInfo(nums)
        this.nextState(ScanSecState.W4_MBUS_RESP)
        break

      // waiting for response from api.mbusScan
      case ScanSecState.W4_MBUS_RESP:
        if (lineStr.match(/collision/g) !== null) {
          // collision happened -> ignore it // TODO: how to handle?
          this.nextState(ScanSecState.W4_MBUS_RESP)
        } else if (lineStr.match(/\[MBUS\]: Scanning done!/g) !== null) {
          // api.mbusScan finnished
          this.nextState(ScanSecState.W4_MBUS_RESP)
        } else if (lineStr.match(/Found \w+ devices/g) !== null) {
          // device count received
          this.nextState(ScanSecState.W4_RESULT)
          // TODO: report found device count
        } else if (lineStr.match(/(\w+ = 0x\d+)/g) !== null) {
          // device is responding to api.mbusScan
          // TODO: do something with device info
          this.nextState(ScanSecState.W4_MBUS_RESP)
        } else {
          // ERROR: unxpected msg
          this.nextState(ScanSecState.ERROR)
        }
        break

      // waiting for formatted results
      // TODO: check if the number of mbus responses is equal to the number of post scan results
      case ScanSecState.W4_RESULT:
        const resp = lineStr.match(/(\w+ = \d+)/g)

        if (resp !== null) {
          // reporting device info
          const KVPairs = resp.map((mo) => mo.split('='))
          const id = parseInt(KVPairs[0][1])
          const devInfo = {
            primaryAddr: undefined,
            id: parseInt(id.toString(16), 10),
            manNum: parseInt(KVPairs[1][1]),
            manStr: this.manNum2Str(parseInt(KVPairs[1][1])),
            ver: parseInt(KVPairs[2][1]),
            medium: parseInt(KVPairs[3][1]),
            rawFrame: undefined,
            parsedFrame: undefined,
          }
          this.context.onLogging(
            `[SCANNER][${getCurrentTime()}]: DEVICE=${JSON.stringify(devInfo)}`
          )
          // this.context.onDeviceInfo(undefined, devInfo)
          this._lastDevInfo = devInfo
          this.nextState(ScanSecState.W4_DETAILS)
        } else if (lineStr.match(/DONE SCANNING/g) !== null) {
          // received "DONE SCANNING"
          this.context.onLogging(
            `[SCANNER][${getCurrentTime()}]: DONE_SCANNING`
          )
          this.context.onScanDone()
        } else {
          this.nextState(ScanSecState.ERROR)
        }
        break

      // waiting for the meter details
      case ScanSecState.W4_DETAILS:
        if (lineStr.length == 0) {
          // no response to details request
          this.context.onLogging(`[SCANNER][${getCurrentTime()}]: NO_DETAILS`)
          // this.context.onDeviceInfo(this._currAddr, undefined)
        } else {
          // device sent details
          this._lastDevInfo.rawFrame = lineStr

          try {
            // @ts-ignore
            const parsedData = tmbus(lineStr)

            this._lastDevInfo.parsedFrame = parsedData
          } catch (error) {
            this._lastDevInfo.parsedFrame = undefined
          }
        }

        this.context.onLogging(
          `[SCANNER][${getCurrentTime()}]: DEVICE=${JSON.stringify(this._lastDevInfo)}`
        )
        this.context.onDeviceInfo(undefined, this._lastDevInfo)
        this.nextState(ScanSecState.W4_RESULT)
        break

      // waiting for "DONE SCANNING"
      // TODO: unused
      case ScanSecState.W4_DONE:
        if (lineStr.match(/DONE SCANNING/g) !== null) {
          this.context.onScanDone()
        } else {
          // expected "DONE SCANNING" but didnt find it
          this.nextState(ScanSecState.ERROR)
        }
        break

      // error happened
      case ScanSecState.ERROR:
        if (lineStr.match(/DONE SCANNING/g) !== null) {
          this.context.onLogging(`[SCANNER][${getCurrentTime()}]: RECOVERED`)
          this.context.onErrorStateRecovery()
        }
        break
    }
  }

  secondaryUnicastFSM(line: number[]) {
    const lineStr = Buffer.from(line).toString('ascii')

    switch (this.state) {
      // waiting for setup response
      case ScanSecState.W4_SETUP:
        const setupRV = lineStr.match(/-?\d+\t-?\d+\t-?\d+\t-?\d+\t-?\d+/)

        if (setupRV === null) {
          // check for old HW response
          if (lineStr.trim().match(/\[STDOUT\]:$/g) !== null) {
            this.context.onStateInfo([])
            this.nextState(ScanSecState.W4_DETAILS)
            break
          }

          // ERROR: expected setup response, but didnt find it
          this.nextState(ScanSecState.ERROR)
          break
        }

        const nums = setupRV[0].trim().split('\t').map(parseInt)
        this.context.onLogging(
          `[SCANNER][${getCurrentTime()}]: MBUSSTATE=${JSON.stringify(nums)}`
        )
        this.context.onStateInfo(nums)
        this.nextState(ScanSecState.W4_DETAILS)
        break

      // waiting for the meter details
      case ScanSecState.W4_DETAILS:
        if (lineStr.length == 0) {
          // no response to details request
          this.context.onLogging(`[SCANNER][${getCurrentTime()}]: NO_DETAILS`)
          this.context.onDeviceInfo(undefined, undefined)
        } else {
          // device sent details

          let devInfo: ScanDeviceInfo
          try {
            // @ts-ignore
            const parsedData = tmbus(lineStr)

            devInfo = {
              primaryAddr: undefined,
              id: parsedData.id,
              manStr: parsedData.manId,
              manNum: this.manStr2Num(parsedData.manId),
              ver: parsedData.version,
              medium: parsedData.deviceCode,
              rawFrame: lineStr,
              parsedFrame: parsedData,
            }
          } catch (error) {
            devInfo = {
              primaryAddr: undefined,
              id: 0,
              manStr: 'XXX',
              manNum: 0,
              ver: 0,
              medium: 0,
              rawFrame: lineStr,
              parsedFrame: undefined,
            }
          }

          this.context.onDeviceInfo(undefined, devInfo)
          this.context.onLogging(
            `[SCANNER][${getCurrentTime()}]: DEVICE=${JSON.stringify(devInfo)}`
          )
        }

        this.nextState(ScanSecState.W4_DONE)
        break

      // waiting for "DONE SCANNING"
      case ScanSecState.W4_DONE:
        if (lineStr.match(/DONE SCANNING/g) !== null) {
          this.context.onScanDone()
        } else {
          // expected "DONE SCANNING" but didnt find it
          this.nextState(ScanSecState.ERROR)
        }
        break

      // error happened
      case ScanSecState.ERROR:
        if (lineStr.match(/DONE SCANNING/g) !== null) {
          this.context.onLogging(`[SCANNER][${getCurrentTime()}]: RECOVERED`)
          this.context.onErrorStateRecovery()
        }
        break
    }
  }

  autoFSM(line: number[]) {
    // TODO:
  }
}
