import { ScanDeviceInfo } from 'src/api/scanner'
import { MBusScannerResultRow } from './MBusScannerResultRow'

export enum MBusScannerResultMsgLvl {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface MBusScannerResultMsg {
  level: MBusScannerResultMsgLvl
  msg: string
}

interface MBusScannerResultsProps {
  msgs: MBusScannerResultMsg[]
  devices: ScanDeviceInfo[]
}

export const MBusScannerResults: React.FC<MBusScannerResultsProps> = ({
  msgs,
  devices,
}) => {
  const level2color = (lvl: MBusScannerResultMsgLvl) => {
    switch (lvl) {
      case MBusScannerResultMsgLvl.INFO:
        return 'info'
      case MBusScannerResultMsgLvl.WARNING:
        return 'warning'
      case MBusScannerResultMsgLvl.ERROR:
        return 'danger'
      default:
        return 'light'
    }
  }

  return (
    <div>
      <div className="mt-3">
        {msgs.map((msg, idx) => (
          <div className={`text-${level2color(msg.level)}`} key={idx}>
            [{msg.level}] {msg.msg}
          </div>
        ))}
      </div>
      {devices.length > 0 && (
        <table className="table table-sm mt-1">
          <thead>
            <tr>
              {['Primary', 'Id', 'ManId', 'Ver', 'Medium'].map(
                (colName, idx) => (
                  <th
                    scope="col"
                    key={idx}
                    className={idx != 0 ? 'text-end' : ''}
                  >
                    {colName}
                  </th>
                )
              )}
              {['Raw', 'Parsed'].map((btnColName, idx) => (
                <th scope="col" key={idx} className="text-center">
                  {btnColName}
                </th>
              ))}
            </tr>
          </thead>
          {devices.map((devInfo, idx) => (
            <MBusScannerResultRow devInfo={devInfo} key={idx} />
          ))}
        </table>
      )}
    </div>
  )
}
