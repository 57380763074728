import molecule from '@dtinsight/molecule'
import { IExtension } from '@dtinsight/molecule/esm/model'
import { IExtensionService } from '@dtinsight/molecule/esm/services'

import { LuaAction } from '../luaCompiler/base'
import { useModal } from 'src/components/ModalProvider'
import TutorialModal from 'src/components/Tutorial'
import { GuiAction } from '../types'
import { NotificationLevel, showNotification } from 'src/common'

export class MenuBarExtension implements IExtension {
  id: string = 'MyMenubar'
  name: string = 'MyMenu Bar'

  activate(extensionCtx: IExtensionService): void {
    this.initUI()

    molecule.activityBar.remove(
      molecule.builtin.getConstants().ACTIVITY_BAR_GLOBAL_ACCOUNT
    )
    molecule.activityBar.remove(
      molecule.builtin.getConstants().ACTIVITY_BAR_GLOBAL_SETTINGS
    )
  }

  initUI() {
    let { builtInMenuBarData } = molecule.builtin.getModules()
    let menuBarData = Object.assign([], builtInMenuBarData)

    for (let i = 0; i < menuBarData.length; i += 1) {
      const { id, data } = menuBarData[i]

      switch (id) {
        case 'File':
          menuBarData[i].data.push({
            id: 'file.fromClipboard',
            name: 'New File From Clipboard',
          })

          menuBarData[i].data.push({
            id: 'file.import',
            name: 'Import Local File(s)',
          })
          
          menuBarData[i].data.push({
            id: 'file.download',
            name: 'Download File'
          })
          break

        case 'Run':
          // TODO: enable actions when some tab with code is active
          menuBarData[i].data = [
            { id: LuaAction.VALIDATE, name: 'Validate', disabled: true },
            {
              id: LuaAction.DEPLOY,
              name: 'Compile and Deploy',
              disabled: true,
            },
          ]
          break

        case 'Help':
          menuBarData[i].data = [
            { id: 'acrios.wiki', name: 'ACRIOS Wiki' },
            { id: 'tutorial', name: 'Tutorial' },
            { id: 'changelog', name: 'Firmware Changelog' },
            { id: 'forceFWUpdate', name: 'Toggle Dev Features' },
            {
              id: 'toggleSerialPortFilters',
              name: 'Toggle Serial Port Filters',
            },
            ...data,
          ]
          break
      }
    }

    setTimeout(() => {
      molecule.menuBar.setMenus(menuBarData)
    })

    molecule.menuBar.onSelect((menuId) => {
      switch (menuId) {
        case 'file.fromClipboard':
          // Get the text from the clipboard
          navigator.clipboard
            .readText()
            .then((text) => {
              if (!text || text.length === 0) {
                showNotification(
                  'clipboard',
                  'No text found in clipboard!',
                  undefined,
                  NotificationLevel.WARNING
                )
                return
              }
              molecule.event.EventBus.emit('createFile', text)
            })
            .catch((err) => {
              console.error('Failed to read clipboard contents: ', err)
            })
          break

        case 'file.download':
          molecule.event.EventBus.emit("downloadFile")
          break

        case 'file.import':
          molecule.event.EventBus.emit('ImportLocalFiles')
          break

        case 'acrios.wiki':
          window.open('https://wiki.acrios.com/', '_blank')
          break

        case LuaAction.VALIDATE:
        case LuaAction.DEPLOY:
          molecule.event.EventBus.emit(menuId)
          break

        case 'About':
          // TODO : do something better
          const version = document.body.getAttribute('data-version')
          const commit = document.body.getAttribute('data-commit')
          alert(`You are running version ${version} from GIT commit ${commit}`)
          break

        case 'tutorial':
          molecule.event.EventBus.emit(GuiAction.SHOW_TUTORIAL)
          break

        case 'changelog':
          window.open(
            'https://wiki.acrios.com/en/acr-cv/cv_changelog',
            '_blank'
          )
          break

        case 'forceFWUpdate':
          molecule.event.EventBus.emit(GuiAction.TOGGLE_FORCE_ENABLE_BOOTLOADER)
          break

        case 'toggleSerialPortFilters':
          window['useSerialFilters'] = !window['useSerialFilters']
          console.log(
            `Filters are now ${
              window['useSerialFilters'] ? 'enabled' : 'disabled'
            }.`
          )
          break

        default:
          console.log('clicked menu item', menuId)
      }
    })
  }

  dispose() {
    molecule.menuBar.reset()
  }
}
