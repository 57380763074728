import './style.css'
import 'reflect-metadata'

import molecule from '@dtinsight/molecule'
import { container } from 'tsyringe'
import React, {
  useCallback,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react'

import { connect } from '@dtinsight/molecule/esm/react'
import { MenuBarController } from '@dtinsight/molecule/esm/controller/menuBar'

import {
  IMenuBar,
  IMenuBarItem,
} from '@dtinsight/molecule/esm/model/workbench/menuBar'
import { IMenuBarController } from '@dtinsight/molecule/esm/controller/menuBar'
import { IMenuProps, Menu, MenuMode } from '@dtinsight/molecule/esm/components'
import { KeybindingHelper } from '@dtinsight/molecule/esm/services/keybinding'
import { MenuBarService } from '@dtinsight/molecule/esm/services'

import {
  SerialContext,
  SerialContextType,
  DEVICE_STATE,
} from '../../api/serialProvider'

import { LuaAction } from '../../extensions/luaCompiler/base'
import { Float, IStatusBarItem } from '@dtinsight/molecule/esm/model'
import { useGlobalState } from '../../state/state'
import LuaWriteModal from '../../components/LuaWriteModal'
import { NotificationLevel, showNotification } from 'src/common'
import { luaFSMStart, FSM_MODE, FsmContext } from 'src/api/luaReadWrite'
import { LUA_SCRIPT_MODE } from 'src/api/serialApi'
import { MAGIC, aesDecrypt } from 'src/api/crypto/crypto'
import xzCompressorDecompressor from 'src/api/xz/xz'
import ScriptPasswordModal from 'src/components/ScriptPasswordModal'
import { fetchAPIData, getAPIFunctionsForVersion } from 'src/api/luaVersions'
import { debug } from 'console'
import { LuaApiFunction } from 'src/types'
import { GuiAction } from 'src/extensions/types'
import DeviceInfoModal from 'src/components/DeviceInfoModal'
import { ReactComponent as AcriosLogo } from '../../assets/images/logo.svg'
import FileNameModal from 'src/components/FileNameModal'
import { set } from 'lodash'
import MBusScannerModal from 'src/components/MBusScannerModal'

const menuBarService = container.resolve(MenuBarService)
const menuBarController = container.resolve(MenuBarController)

const Button = molecule.component.Button
const DropDown = molecule.component.DropDown

function MenuBarActions() {
  const [deviceDetected, setDeviceDetected] = useGlobalState('deviceDetected')
  const [_deviceType, setDeviceType] = useGlobalState('deviceType')
  const [deviceFwVersion, _setDeviceFwVersion] =
    useGlobalState('deviceFwVersion')
  const [bootLoaderLegacy, setBootLoaderLegacy] =
    useGlobalState('bootLoaderLegacy')
  const [localEchoTerm, _setLocalEchoTerm] = useGlobalState('localEchoTerm')
  const [showWriteModal, setShowWriteModal] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [showFileNameModal, setShowFileNameModal] = useState(false)
  const [passwordSubmitCallback, setPasswordSubmitCallback] = useState(null)
  const [passwordInvalid, setPasswordInvalid] = useState(false)
  const [luaApi, setLuaApi] = useState<LuaApiFunction[]>([])
  const [forceEnableBootloader, setForceEnableBootloader] = useState(false)
  const [showDeviceInfo, setShowDeviceInfo] = useState(false)
  const [showMBusScanner, setShowMBusScanner] = useState(false)

  const clipboardTextRef = useRef('')

  const termAutocompleteHandler = useCallback(() => {
    return luaApi.map((api) => api.function)
  }, [luaApi])

  useEffect(() => {
    const prefetchWikiApi = async () => {
      await fetchAPIData()
      console.log('Lua Api fetched from Wiki')
    }

    prefetchWikiApi()
  }, [])

  useEffect(() => {
    if (Object.keys(deviceFwVersion).length) {
      const api = getAPIFunctionsForVersion(deviceFwVersion)
      if (localEchoTerm) {
        localEchoTerm.removeAutocompleteHandler(termAutocompleteHandler)
      }
      setLuaApi(api)
    }
  }, [deviceFwVersion, localEchoTerm])

  useEffect(() => {
    console.log(
      'Adding autocomplete handler with luaApi: ',
      termAutocompleteHandler()
    )
    if (localEchoTerm) {
      localEchoTerm.addAutocompleteHandler(termAutocompleteHandler)
    }
  }, [luaApi, localEchoTerm])

  const handleCloseWriteModal = () => {
    setShowWriteModal(false)
  }
  const handleShowWriteModal = () => {
    const tab = molecule.editor.getState()?.current?.tab
    if (!tab) {
      return
    }
    setShowWriteModal(true)
  }

  const {
    port,
    deviceState,
    openClosePort,
    switchToConfigMode,
    changeDeviceState,
  } = useContext(SerialContext) as SerialContextType

  const canUpdateFw =
    deviceState == 'connected' ||
    (deviceState == 'serialLogging' &&
      (forceEnableBootloader || deviceState === DEVICE_STATE.NG_BL_ONLY))

  const [activeTab, setActiveTab] = useState(undefined)

  const isConnected = deviceState === DEVICE_STATE.CONNECTED
  const isInteractiveMode = deviceState === DEVICE_STATE.INTERACTIVE_MODE
  const isWriting = deviceState === DEVICE_STATE.WRITING
  const isUpdatingFW =
    deviceState === DEVICE_STATE.FW_UPDATE ||
    deviceState === DEVICE_STATE.FW_UPDATE_NG

  const handleRead = useCallback(() => {
    molecule.event.EventBus.emit(LuaAction.READ)
  }, [])

  const handleValidate = useCallback(() => {
    molecule.event.EventBus.emit(LuaAction.VALIDATE)
  }, [])

  const handleWrite = useCallback((payload) => {
    if (!payload) {
      showNotification(
        'luaCompiler',
        "Can't write empty payload",
        undefined,
        NotificationLevel.ERROR
      )
      return
    }

    luaFSMStart(FSM_MODE.WRITE, payload)

    changeDeviceState(DEVICE_STATE.WRITING)
  }, [])

  const handleInteractiveMode = useCallback(() => {
    molecule.event.EventBus.emit(
      isInteractiveMode === false
        ? LuaAction.INTERACTIVE_MODE_START
        : LuaAction.INTERACTIVE_MODE_STOP
    )
  }, [isInteractiveMode])

  const handleFWUpdate = (action) => (event) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = () => {
        const arrayBuffer = reader.result
        const bytes = new Uint8Array(arrayBuffer as ArrayBuffer)
        console.log('loaded fw file', file, bytes)
        molecule.event.EventBus.emit(action, bytes)
        event.target.value = '' // reset so that the on change event works next time
      }
    }
  }

  useEffect(() => {
    molecule.event.EventBus.subscribe('activeTab', () => {
      setActiveTab(molecule.editor.getState()?.current?.tab || undefined)
    })
    return () => {
      molecule.event.EventBus.unsubscribe(['activeTab'])
    }
  }, [])

  useEffect(() => {
    molecule.event.EventBus.subscribe(
      GuiAction.TOGGLE_FORCE_ENABLE_BOOTLOADER,
      () => {
        setForceEnableBootloader((enabled) => !enabled)
      }
    )
    return () => {
      molecule.event.EventBus.unsubscribe([
        GuiAction.TOGGLE_FORCE_ENABLE_BOOTLOADER,
      ])
    }
  }, [])

  let handlePasswordSubmit = (submittedPassword) => {
    if (passwordSubmitCallback) {
      passwordSubmitCallback(submittedPassword)
    }
  }

  const requestPassword = () => {
    return new Promise<string>((resolve, reject) => {
      // Set the callback in the state
      setPasswordSubmitCallback(() => (newPassword) => {
        if (newPassword === null) {
          reject(newPassword)
        }
        resolve(newPassword)
      })
    })
  }

  const processReadPayload = useCallback(async (fsmContext: FsmContext) => {
    try {
      const enc = fsmContext.scriptMode === LUA_SCRIPT_MODE.EXZ

      let source = Uint8Array.from(fsmContext.xz)
      if (source.length === 0) {
        console.error(`No text script in device!`)
        showNotification(
          'luaState',
          `Script is not saved in text format, only in binary!`,
          undefined,
          NotificationLevel.ERROR
        )
        return
      }
      // encrypted source with magic
      let uint8Array
      if (enc) {
        setShowPasswordModal(true)
        while (true) {
          const pass = await requestPassword()
          try {
            uint8Array = await aesDecrypt(source, pass)
            break
          } catch (error) {
            console.error('[LUA] AES decrypt error')
            console.error(error)
            showNotification('luaState', `Wrong password`)
            setPasswordInvalid(true)
          }
        }

        const match = MAGIC.every((value, index) => uint8Array[index] === value)
        if (!match) throw 'Key does not match!'

        // get rid of magic numbers at the start
        source = uint8Array.slice(4)
      }

      const xzAns = await xzCompressorDecompressor({
        input: source,
        doDecompression: true,
      })

      fsmContext.lua = new TextDecoder().decode(xzAns)

      // TODO: add device type to file name
      if (fsmContext.lua.length > 0) {
        const name = `readout_${Date.now()}.lua`

        showNotification(
          'luaState',
          `Script read complete into ${name}`,
          'pass-filled'
        )

        //Open a new tab and dump the data into the new tab
        molecule.event.EventBus.emit('ImportLuaReadout', fsmContext.lua)
      } else {
        console.error(`Empty readout!`)
        showNotification(
          'luaState',
          `Script is corrupt!`,
          undefined,
          NotificationLevel.ERROR
        )
      }
    } catch (error) {
      console.error(error)
      showNotification(
        'luaState',
        `[LUA] Error reading payload from device: `,
        error
      )
    } finally {
      setPasswordSubmitCallback(null)
      setPasswordInvalid(false)
      setShowPasswordModal(false)
    }
  }, [])

  useEffect(() => {
    molecule.event.EventBus.subscribe('luaReadDone', processReadPayload)
    return () => {
      molecule.event.EventBus.unsubscribe(['luaReadDone'])
    }
  }, [processReadPayload])

  let createNewFile = (fileName: string) => {
    molecule.event.EventBus.emit('newFile', fileName, clipboardTextRef.current)
    setShowFileNameModal(false)
  }

  const handleCreateFileEvent = useCallback((content: string) => {
    clipboardTextRef.current = content
    setShowFileNameModal(true)
  }, [])

  useEffect(() => {
    molecule.event.EventBus.subscribe('createFile', handleCreateFileEvent)
    return () => {
      molecule.event.EventBus.unsubscribe(['createFile'])
    }
  }, [handleCreateFileEvent])

  // Warn user if they try to leave the page with connected device
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (deviceState !== DEVICE_STATE.SERIAL_PORT_CLOSED) {
        const message =
          'Device is still connected and leaving page can cause issues. Are you sure you want to leave?'
        e.returnValue = message
        return message // For older browsers
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [deviceState])

  // "Download File" event handler
  const handleFileDownloadEvent = useCallback(() => {
    // is editor closed?
    if (molecule.editor.editorInstance === undefined) {
      showNotification('noEditorOpen', 'No opened file.')
      return
    }

    const editorText = molecule.editor.editorInstance.getValue()
    const fileName = molecule.editor.getState().current.tab.name

    // download currently open file using a virtual element
    let element = document.createElement('a')
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(editorText)
    )
    element.setAttribute('download', fileName)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }, [])

  useEffect(() => {
    molecule.event.EventBus.subscribe('downloadFile', handleFileDownloadEvent)
    return () => {
      molecule.event.EventBus.unsubscribe(['downloadFile'])
    }
  }, [handleFileDownloadEvent])

  const isMBusDevice = () => {
    return _deviceType.match(/([A-Z]+_)M_/g) !== null
  }

  return (
    <div className="menuBarActions">
      {window.location.href.startsWith('https://dev.') ? (
        <div className="blinking"> THIS IS A DEVELOPMENT VERSION !!!</div>
      ) : undefined}

      <Button
        onClick={openClosePort}
        disabled={isInteractiveMode || isWriting || isUpdatingFW}
      >
        {port.current.isConnected &&
        deviceState !== DEVICE_STATE.SERIAL_PORT_CLOSED
          ? 'Disconnect'
          : 'Connect'}
      </Button>

      {deviceState === DEVICE_STATE.SERIAL_LOGGING && (
        <Button onClick={switchToConfigMode}>Config</Button>
      )}

      <Button onClick={handleRead} disabled={!isConnected}>
        Read
      </Button>

      <Button onClick={handleValidate} disabled={activeTab === undefined}>
        Validate
      </Button>

      <Button
        onClick={handleShowWriteModal}
        disabled={!(isConnected && !!activeTab)}
      >
        Write
      </Button>

      {showWriteModal && (
        <LuaWriteModal
          show={showWriteModal}
          onHide={handleCloseWriteModal}
          onWrite={handleWrite}
        />
      )}

      {showPasswordModal && (
        <ScriptPasswordModal
          passwordInvalid={passwordInvalid}
          show={showPasswordModal}
          handleSubmit={handlePasswordSubmit}
        />
      )}

      {showFileNameModal && (
        <FileNameModal
          show={showFileNameModal}
          setShow={setShowFileNameModal}
          handleSubmit={createNewFile}
        />
      )}

      <Button
        onClick={handleInteractiveMode}
        disabled={!(isConnected || isInteractiveMode)}
      >
        {isInteractiveMode ? (
          <>Quit&nbsp;interactive&nbsp;mode</>
        ) : (
          <>Interactive&nbsp;mode</>
        )}
      </Button>

      {!isConnected && !forceEnableBootloader && (
        <Button disabled>
          <label htmlFor="fwUpdate">Update&nbsp;FW</label>
        </Button>
      )}

      {((isConnected && !bootLoaderLegacy) || forceEnableBootloader) && (
        <>
          <input
            type="file"
            id="fwUpdate"
            accept=".fw2"
            onChange={handleFWUpdate(LuaAction.FW_UPDATE_NG)}
          />
          <Button disabled={!canUpdateFw}>
            <label htmlFor="fwUpdate">Update&nbsp;FW&nbsp;LoRaWAN</label>
          </Button>
        </>
      )}

      {((isConnected && bootLoaderLegacy) || forceEnableBootloader) && (
        <>
          <input
            type="file"
            id="fwUpdateLegacy"
            accept=".fw"
            onChange={handleFWUpdate(LuaAction.FW_UPDATE)}
          />
          <Button disabled={!canUpdateFw}>
            <label htmlFor="fwUpdateLegacy">
              Update&nbsp;FW&nbsp;NB&#8209;IoT
            </label>
          </Button>
        </>
      )}

      <Button
        onClick={() => setShowDeviceInfo(true)}
        disabled={
          !(
            deviceState == DEVICE_STATE.CONNECTED ||
            deviceState == DEVICE_STATE.INTERACTIVE_MODE ||
            deviceState == DEVICE_STATE.READING ||
            deviceState == DEVICE_STATE.WRITING
          )
        }
      >
        Device&nbsp;Info
      </Button>

      {showDeviceInfo && (
        <DeviceInfoModal
          show={showDeviceInfo}
          onClose={() => setShowDeviceInfo(false)}
          deviceState={{
            type: _deviceType,
            fwVersion: deviceFwVersion,
            state: deviceState,
          }}
        />
      )}

      {isMBusDevice() && (
        <Button
          onClick={() => {
            setShowMBusScanner(true)
          }}
          disabled={!(deviceState == DEVICE_STATE.CONNECTED)}
        >
          M&#8209;Bus&nbsp;Scan
        </Button>
      )}

      {showMBusScanner && (
        <MBusScannerModal
          show={showMBusScanner}
          onClose={() => setShowMBusScanner(false)}
        />
      )}
    </div>
  )
}

export function MenuBar(props: IMenuBar & IMenuBarController) {
  const { data, onClick, updateFocusinEle } = props

  const addKeybindingForData = (rawData: IMenuBarItem[] = []): IMenuProps[] => {
    const resData: IMenuProps[] = rawData.concat()
    const stack = [...resData]
    while (stack.length) {
      const head = stack.pop()
      if (head) {
        if (head?.data) {
          stack.push(...head.data)
        } else {
          const simplyKeybinding =
            KeybindingHelper.queryGlobalKeybinding(head.id!) || []
          if (simplyKeybinding.length) {
            head.keybinding =
              KeybindingHelper.convertSimpleKeybindingToString(simplyKeybinding)
          }
        }
      }
    }
    return resData
  }

  const handleClick = (e: React.MouseEvent, item: IMenuBarItem) => {
    onClick?.(e, item)
  }

  const handleSaveFocusinEle = useCallback(
    (e: FocusEvent) => {
      updateFocusinEle?.(e.target as HTMLElement | null)
    },
    [updateFocusinEle]
  )

  useEffect(() => {
    document.body.addEventListener('focusin', handleSaveFocusinEle)
    return () => {
      document.body.removeEventListener('focusin', handleSaveFocusinEle)
    }
  }, [handleSaveFocusinEle])

  return (
    <div className="myMenuBar">
      <AcriosLogo
        style={{
          width: '40px',
          height: '40px',
          marginLeft: '.9rem',
          marginRight: '1rem',
        }}
      />

      <Menu
        role="menu"
        mode={MenuMode.Horizontal}
        trigger="click"
        onClick={handleClick}
        style={{ width: '100%' }}
        data={addKeybindingForData(data)}
      />
      <MenuBarActions />
    </div>
  )
}

const MyMenuBarView = connect(menuBarService, MenuBar, menuBarController)

export default MyMenuBarView
